import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";

import "./App.css"

import Home from "./pages/home";
import Aboutfavela from "./pages/aboutfavela";
import About from "./pages/about";
import Programs from "./pages/programs";
import NoPage from "./pages/404";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="aboutfavela" element={<Aboutfavela />} />
          <Route path="programs" element={<Programs />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
