import { Outlet, Link } from "react-router-dom";
import ReactDOM from "react-dom/client";
import React, { useState } from 'react';
import '../index.css';

import { FormattedMessage } from "react-intl";

const Home = () => {
    return(
        <div class="home-head bg-faveladark w-full h-screen flex flex-col items-center pt-40">
          <div class="p-4 width-full flex flex-col items-center justify-center">
            <img src="/assets/logo-letters-peach.png" alt="home" class="h-32 p-6 md:p-2" />

            <div class="text-white mt-8 md:text-xl px-6 text-center bg-faveladark">
              <FormattedMessage id="home_favela_short_desc" />
            </div>

          </div>
        </div>
    ) ;
  };
  
export default Home;