import { Outlet, Link } from "react-router-dom";
import ReactDOM from "react-dom/client";
import React, { useState } from 'react';
import '../index.css';

import { IntlProvider } from "react-intl";
import { LOCALES } from "../languages/locales";
import { messages } from "../languages/messages";

import { FormattedMessage } from "react-intl";

const Layout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [contactOpen, setContactOpen] = useState(false);
    const [languageCro, changeLanguage] = useState(true);

    const handleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
    };

    const handleContact = () => {
        setContactOpen(!contactOpen);
      };

    const handleLanguage = () => {
        changeLanguage(!languageCro);
      };

    const locale = (languageCro ? LOCALES.CROATIAN : LOCALES.ENGLISH);
  return (
   <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale={LOCALES.CROATIAN}>
    
        <nav class="fixed navbar-conf w-full h-20 top-0 left-0 p-2">
        {/* Navbar */}
            <div class="float-left"><Link to="/"><img src="/assets/logo-leaf-white.png" alt="home" class="w-[60px] h-[60px] p-2" /></Link></div>
            <div class="float-right m-4">                
                <svg viewBox="0 0 50 50" width="50px" height="50px" class="w-8 h-8 fill-white hover:fill-favela cursor-pointer" onClick={handleSidebar}>
                    <path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"/>
                </svg>
            </div>
        </nav>

        <Outlet/>
 
        <div class={`flex flex-col justify-between items-stretch w-[100%] md:w-[400px] sidebar ${sidebarOpen === true ? 'active' : ''}`}>
        {/* Sidebar Content Holdder */}
    
            <div class="w-full h-20 p-2">
            {/* CLOSE svg */}
                <div class="float-right">
                    <svg class="fill-white hover:fill-favela w-16 h-16 cursor-pointer" onClick={handleSidebar}  viewBox="0 0 256 256" id="Flat">
                        <path d="M202.82861,197.17188a3.99991,3.99991,0,1,1-5.65722,5.65624L128,133.65723,58.82861,202.82812a3.99991,3.99991,0,0,1-5.65722-5.65624L122.343,128,53.17139,58.82812a3.99991,3.99991,0,0,1,5.65722-5.65624L128,122.34277l69.17139-69.17089a3.99991,3.99991,0,0,1,5.65722,5.65624L133.657,128Z"/>
                    </svg>
                </div>
            </div>

            <div>
            {/* Sidebar Options and Address holder */}
                
                <Link to="/aboutfavela" onClick={handleSidebar} class="w-full py-2 pr-6 text-6xl text-favela hover:text-white flex items-center justify-end">
                    <FormattedMessage id="menu_about_favela" />
                </Link>

                <Link to="/about" onClick={handleSidebar} class="w-full py-2 pr-6 text-6xl text-favela hover:text-white flex items-center justify-end">
                    <FormattedMessage id="menu_about" />
                </Link>
                
                <Link to="/programs" onClick={handleSidebar} class="w-full py-2 pr-6 text-6xl text-favela hover:text-white flex items-center justify-end">
                    <FormattedMessage id="menu_programs" />
                </Link>
    
                <div class={`flex border-t mt-6 border-white flex-col py-4 text-white contact active`}>
                    <div class="flex justify-end pr-6">
                        <FormattedMessage id="contact_street" />
                    </div>

                    <div class="flex justify-end pr-6">
                        <FormattedMessage id="contact_town" />
                    </div>

                    <div class="w-full py-4 pr-6 flex gap-4 items-center justify-end">
                        <Link target="_blank" to="https://www.instagram.com/favela.uo/">
                            <svg  x="0px" y="0px" viewBox="0 0 24 24" class="fill-favela hover:fill-white w-8 h-8 cursor-pointer">
                                <g>
                                    <path d="M12,2.162c3.204,0,3.584,0.012,4.849,0.07c1.308,0.06,2.655,0.358,3.608,1.311c0.962,0.962,1.251,2.296,1.311,3.608   c0.058,1.265,0.07,1.645,0.07,4.849c0,3.204-0.012,3.584-0.07,4.849c-0.059,1.301-0.364,2.661-1.311,3.608   c-0.962,0.962-2.295,1.251-3.608,1.311c-1.265,0.058-1.645,0.07-4.849,0.07s-3.584-0.012-4.849-0.07   c-1.291-0.059-2.669-0.371-3.608-1.311c-0.957-0.957-1.251-2.304-1.311-3.608c-0.058-1.265-0.07-1.645-0.07-4.849   c0-3.204,0.012-3.584,0.07-4.849c0.059-1.296,0.367-2.664,1.311-3.608c0.96-0.96,2.299-1.251,3.608-1.311   C8.416,2.174,8.796,2.162,12,2.162 M12,0C8.741,0,8.332,0.014,7.052,0.072C5.197,0.157,3.355,0.673,2.014,2.014   C0.668,3.36,0.157,5.198,0.072,7.052C0.014,8.332,0,8.741,0,12c0,3.259,0.014,3.668,0.072,4.948c0.085,1.853,0.603,3.7,1.942,5.038   c1.345,1.345,3.186,1.857,5.038,1.942C8.332,23.986,8.741,24,12,24c3.259,0,3.668-0.014,4.948-0.072   c1.854-0.085,3.698-0.602,5.038-1.942c1.347-1.347,1.857-3.184,1.942-5.038C23.986,15.668,24,15.259,24,12   c0-3.259-0.014-3.668-0.072-4.948c-0.085-1.855-0.602-3.698-1.942-5.038c-1.343-1.343-3.189-1.858-5.038-1.942   C15.668,0.014,15.259,0,12,0z"/>
                                    <path d="M12,5.838c-3.403,0-6.162,2.759-6.162,6.162c0,3.403,2.759,6.162,6.162,6.162s6.162-2.759,6.162-6.162   C18.162,8.597,15.403,5.838,12,5.838z M12,16c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S14.209,16,12,16z"/>
                                    <circle cx="18.406" cy="5.594" r="1.44"/>
                                </g>
                            </svg>
                        </Link>

                        <Link to='#' onClick={(e) => {
                            window.location.href = "mailto:info@favela.hr";
                            e.preventDefault();}}>

                            <svg viewBox="0 0 24 24" class="w-8 h-8 cursor-pointer fill-favela hover:fill-white">
                                <path d="M19,1H5A5.006,5.006,0,0,0,0,6V18a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1ZM5,3H19a3,3,0,0,1,2.78,1.887l-7.658,7.659a3.007,3.007,0,0,1-4.244,0L2.22,4.887A3,3,0,0,1,5,3ZM19,21H5a3,3,0,0,1-3-3V7.5L8.464,13.96a5.007,5.007,0,0,0,7.072,0L22,7.5V18A3,3,0,0,1,19,21Z"/>
                            </svg>    

                        </Link>

                        <Link to="https://drive.google.com/drive/folders/1w0vcly9hV3E11FEzaWgItBE03OR9CcDP" target="_blank">                            
                            <svg viewBox="0 0 24 24" class="w-8 h-8 fill-favela hover:fill-white">
                                <path d="m17 14a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"/>
                            </svg>
                        </Link>
                    </div>
                </div>

            </div>

            <div class="p-6">
            {/* Sidebar Languages and Donation holder*/}    
                <div class="mb-6 float-right flex gap-12 text-2xl justify-end">
                    <div class={`cursor-pointer ${languageCro === true ? ' text-favela ' : ' text-white '}`} onClick={handleLanguage}>EN</div>
                    <div class={`cursor-pointer ${languageCro === true ? ' text-white ' : ' text-favela '}`} onClick={handleLanguage}>HR</div>
                </div>

                <div class="w-full flex justify-end mt-12">
                    <Link to="https://www.buymeacoffee.com/favela.uo" onClick={handleSidebar} class="font-thin text-favela hover:text-white border-2 border-favela hover:border-white w-60 h-12 flex items-center justify-center cursor-pointer">
                        <FormattedMessage id="menu_coffee" /> 
                    
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pl-2 cursor-pointer" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" fill="#fa605e" width="512" height="512">
                            <path d="M8.965,24H4a4,4,0,0,1-4-4V15a4,4,0,0,1,4-4h8.857a3.144,3.144,0,0,1,2.69,1.519l3.217-3.535a3.01,3.01,0,0,1,4.254-.2,3.022,3.022,0,0,1,.217,4.23l-6.8,7.637A10.012,10.012,0,0,1,8.965,24ZM4,13a2,2,0,0,0-2,2v5a2,2,0,0,0,2,2H8.965a8.005,8.005,0,0,0,5.972-2.678l6.805-7.638a1.015,1.015,0,0,0-.072-1.421A1.029,1.029,0,0,0,20.942,10a1,1,0,0,0-.7.329L15.816,15.2A3.158,3.158,0,0,1,13.3,17.252l-5.161.738a1,1,0,0,1-.284-1.98l5.162-.737A1.142,1.142,0,0,0,12.857,13Zm7-3.926a1.986,1.986,0,0,1-1.247-.436C8.041,7.264,6,5.2,6,3.2A3.109,3.109,0,0,1,9,0a2.884,2.884,0,0,1,2,.817A2.884,2.884,0,0,1,13,0a3.109,3.109,0,0,1,3,3.2c0,2-2.041,4.064-3.754,5.439A1.986,1.986,0,0,1,11,9.074ZM9,2A1.115,1.115,0,0,0,8,3.2c0,.9,1.151,2.39,3.006,3.879C12.849,5.59,14,4.1,14,3.2A1.115,1.115,0,0,0,13,2a1.115,1.115,0,0,0-1,1.2,1,1,0,0,1-2,0A1.115,1.115,0,0,0,9,2Z"/>
                        </svg>

                    </Link>
                </div>
            </div>

            <div id="root"></div>


        </div>
    </IntlProvider>
    )};
 
export default Layout;