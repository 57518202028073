import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Layout from "./layout";

const About = () => {
  return <div class="w-full grid grid-cols-2">
      <div class="col-span-2 md:col-span-1 h-max border flex flex-col items-center">
            <img src="/assets/Fani.png" alt="fani" class="w-[50%] h-auto m-12 rounded-full" />

            <div class="text-center flex flex-col items-center">
              <div class="text-favela font-bolder text-4xl">FANI SOLOMUN</div>
              <div class="text-white font-bold text-xl">
                <FormattedMessage id="about_fani_role" />
              </div>
            </div>

            <div class="p-12 text-white font-light text-md md:text-xl">
              <FormattedMessage id="about_fani_more" />
            </div>

        </div>

        <div class="col-span-2 md:col-span-1 h-max border flex flex-col items-center">
            <img src="/assets/Hana.png" alt="hana" class="w-[50%] h-auto m-12 rounded-full" />

            <div class="text-center flex flex-col items-center">
              <div class="text-favela font-bolder text-4xl">HANA KUNIĆ</div>
              <div class="text-white font-bold text-xl">
                <FormattedMessage id="about_hana_role"/>
              </div>
            </div>

            <div class="p-12 text-white font-light text-md md:text-xl">
              <FormattedMessage id="about_hana_more"/>
            </div>
        </div>
  </div>;
};
  
export default About;