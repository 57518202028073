import '../index.css';

import { FormattedMessage } from "react-intl";

const Aboutfavela = () => {
  return <div class="aboutfavela-background w-full min-h-screen flex flex-col items-center pt-40">
            <div class="w-[80%] md:w-[60%] p-4 md:p-12 border border-favela flex flex-col items-center justify-center">
                
                <div class="w-full md:px-12 flex gap-12 flex items-center justify-center">
                    <img src="/assets/logo-letters-white.png" alt="home" class="w-[70%] h-auto" />
                </div>
            
                <div class="w-full p-6 text-center text-white text-xl bg-faveladark mt-8 bg-opacity-80">
                    <FormattedMessage id="about_favela_page" values={{"b": chunks => <span class="font-bold">{chunks}</span>, "i": chunks => <span class="italic">{chunks}</span>, "span": chunks => <div class="w-full my-4">{chunks}</div>}}/>
                </div>    
            </div>
        </div>;};
  
export default Aboutfavela;